@import '~@angular/material/theming';

// always include only once per project
@include mat-core();
// define 3 theme color
// mat-palette accepts $palette-name, main, lighter and darker variants
$wbhs-theme-primary: mat-palette($mat-indigo, 700, 300, 900);
$wbhs-theme-accent: mat-palette($mat-light-blue);
$wbhs-theme-warn: mat-palette($mat-deep-orange, A200);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$wbhs-theme: mat-light-theme(
    $wbhs-theme-primary,
    $wbhs-theme-accent,
    $wbhs-theme-warn
);

body {
    font-family: Roboto,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
}
